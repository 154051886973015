<template >
  <div v-if="shipments" id="modalInvoice">
    <v-container v-for="(item, i) in shipments" :key="i">
      <div>
        <app-barcode :shipment="item"> </app-barcode>
      </div>
      <div class="pagebreak"></div>
    </v-container>

  </div>
</template>

<script>
import ShipmentService from "./../../LandShipping/services/ShipmentService";
import AppBarcode from "./components/AppBarcode.vue";
export default {
  components: {
    AppBarcode,
  },
  mounted() {
    let model = {
        isClient: this.$route.query["isClient"],
        distributorId: +this.$route.query["distributorId"],
        shipmentsIds: JSON.parse(this.$route.query["shipmentsIds"]),
      };
    if (model.distributorId) this.getDataFromAPI(model);
    else this.getDataFromStore();
  },

  data() {
    return {
      trip: {},
      shipments: [],
      printData: null,
    };
  },
  methods: {
    getDataFromAPI(modal) {
      ShipmentService.byDistributorId(modal).then((resp) => {
        this.printData = {};
        this.shipments = resp.data;
        setTimeout(() => {
            const options = {
              styles: [
                "https://2023.al-ahmari.com.sa/Data/Images/ExternalShippers/style.css",
              ],
              timeout: 1000,
              autoClose: true,
              windowTitle: window.document.title,
            };
            this.$htmlToPaper("modalInvoice", options);
          }, 100);
      });
    },
    getDataFromStore() {
      this.printData = this.$store.getters.printData;
      this.trip = this.printData.trip;
      this.shipments = this.printData.shipments;
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}

#modalInvoice .div:not(:first-child) { margin-top: 160px !important; }




</style>
