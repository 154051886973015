import Api from '../../shared/services/api'

const baseUrl = "ClientPrices";
export default {

    get(entity, options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + `/list/${entity.id}`+ "?" + qParams.toString());
    },
    gettoadd(entity) {
        return Api().get(baseUrl + `/list/${entity.id}`+ "?ItemsPerPage=-1");
    },
    gettoaddphoneNumber(entity) {
        return Api().get(baseUrl + `/getByphoneNumber/${entity.phoneNumber}`+ "?ItemsPerPage=-1");
    },
    query(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "?" + qParams.toString());
    },
    edit(entity) {
        if (entity.id)
            return Api().put(baseUrl + `/${entity.id}`, entity.clientData);
        else
            return this.add(entity);
    },
    add(entity) {
        return Api().post(baseUrl + `/${entity.clientId}`, entity.clientData);
    },
    delete(id) {
        return Api().delete(baseUrl + `/${id}`);
    }
}