<template>
  <v-app>
    <div>
      <v-row class="pr-5 pb-3 mt-3">
        <v-col class="d-flex justify-content-start">
          <v-btn v-can="'Clients.Create'" color="success" @click="add">
            اضافة عميل<i class="fa fa-plus mr-2"></i>
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-content-end">
          <v-btn v-can="'Salesmen.Create'" class="white--text" color="blue" @click="viewTop50">
            اعلى 50 عميل
          </v-btn>
        </v-col>
      </v-row>

      <!-- Add Modal -->
      <v-dialog v-model="dialog" width="99%" persistent>
        <v-card>
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <!-- header  -->
            <v-row class="my-0 ammar" dense>
              <v-col cols="12">
                <v-tabs v-model="activeTab" fixed-tabs next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline" show-arrows center-active background-color="orange accent-1"
                  color="black" class="rounded-lg w-100" light>
                  <v-tab @click="showClient()">
                    {{ mode === 0 ? "اضافة عميل" : "تعديل عميل" }}
                  </v-tab>
                  <v-tab @click="showProduct()" :disabled="!selected.id">
                    {{ mode === 0 ? "اضافة صنف" : "تعديل صنف" }}
                  </v-tab>
                  <v-tab @click="showBranch()" :disabled="!selected.id">
                    {{ mode === 0 ? "اضافة فرع" : "تعديل فرع" }}
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <!-- add client  -->
            <v-form v-model="valid" class="mt-5">
              <v-row v-if="showClientTab && dialog" class="my-0 ammar" dense>
                <v-col cols="12" sm="3">
                  <v-text-field outlined dense label="الاسم" v-model="selected.name"
                    :rules="[allRules.required, allRules.minNameLen(4)]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field outlined dense label="اسم المسؤل" v-model="selected.empName"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field outlined dense label="المسمي الوظيفي" v-model="selected.empTitle"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-autocomplete dense outlined hide-details auto-select-first label="المدينة" :items="citiesadd"
                    item-value="id" item-text="name" v-if="citiesadd" v-model="selected.branchId"
                    :rules="[allRules.required]"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field :maxlength="10" outlined dense label="رقم الهاتف" v-model="selected.phoneNumber">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field :maxlength="10" outlined dense label="رقم الهوية" v-model="selected.idNum"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field :maxlength="15" outlined dense label="الرقم الضريبي" v-model="selected.taxNum">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field :maxlength="10" outlined dense label="رقم السجل التجاري"
                    v-model="selected.commercialRegNum"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined label="ملاحظات" v-model="selected.notice"></v-textarea>
                </v-col>
                <v-card-actions class="">
                  <div>
                    <v-btn class="primary white--text" @click="addClient" :disabled="!valid">

                      {{ mode === 0 ? "اضافه" : "تعديل" }}
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-row>
            </v-form>

            <!-- product  -->
            <v-form v-model="validProduct">
              <v-row v-if="showProductTab && dialog" class="my-0 ammar" dense>
                <v-card-title class="headline">
                  <!-- chips clearable deletable-chips multiple small-chips <i class="fa fa-pen ml-2" ></i> تعديل/إضافة  -->
                  اضافة صنف
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row class="my-0 ammar" dense>
                      <v-col cols="12" sm="3" v-show="!loading">
                        <v-autocomplete chips clearable deletable-chips multiple small-chips :rules="[allRules.required]"
                          dense outlined label="الصنف" :items="MerchandisesList" v-show="!loading" item-text="name"
                          return-object auto-select-first v-model="selected.product.merchandise">
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="2" v-show="!loading">
                        <v-autocomplete :rules="[allRules.required]" dense outlined chips clearable deletable-chips
                          multiple small-chips label=" المسار" :items="lines" item-text="name" return-object
                          auto-select-first v-model="selected.product.line">
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="2" v-if="selected.product.merchandise.length > 0">
                        <v-text-field type="number" outlined dense disabled label="السعر العام"
                          v-model="selected.product.merchandise[0].price"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field type="number" outlined dense label="السعر الخاص" :rules="[allRules.required]"
                          v-model="selected.product.price"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" class="d-flex align-items-center">
                        <v-switch class="mt-0 pt-0" v-model="selected.product.includeTax" dense hide-details
                          label="متضمن  الضريبه"></v-switch>
                        <span>
                          <v-text-field disabled dense outlined label=" بعد الضريبه"
                            v-model="selected.product.priceAfterTax"></v-text-field>
                        </span>
                        <span class="mr-3">
                          <v-btn color="success" @click="addMerchandise()" :disabled="!validProduct">

                            {{ mode === 0 ? "اضافه" : "تعديل" }}
                          </v-btn>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="my-0 ammar text-center" dense v-if="allProducts">
                      <v-col cols="12" v-if="allProducts.length > 0">
                        <v-data-table :headers="productsHeaders" :items="allProducts" :options.sync="optionsproducts"
                          :server-items-length="allProductstotal" fixed-header class="elevation-1" :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-plus',
                            nextIcon: 'mdi-minus',
                            'items-per-page-text':
                              'عدد الاصناف  ' + allProductstotal + '',
                          }">
                          <template v-slot:[`item.includeTax`]="{ item }">
                            <span v-if="item.includeTax">
                              <v-chip label color="info">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </v-chip>
                            </span>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="red" class="ml-2" icon elevation="2" outlined @click="deleteProduct(item.id)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn color="primary" elevation="2" icon @click="editProduct(item)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-row>
            </v-form>

            <v-form v-model="validBranch">
              <!-- branch  -->
              <v-row v-if="showBranchTab && dialog" class="my-0 ammar" dense>
                <v-card-title class="headline">
                  <!-- <i class="fa fa-pen ml-2" ></i> تعديل/إضافة  -->
                  اضافة فرع
                </v-card-title>
                <v-card-text>
                  <v-row class="my-0 ammar" dense>
                    <v-col cols="12" sm="4">
                      <v-text-field :rules="[allRules.required, allRules.minNameLen(3)]" hide-details outlined dense
                        label="اسم الفرع" v-model="selected.branch.name"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete :rules="[allRules.required]" dense outlined label="المدينة" :items="cities"
                        item-text="name" return-object auto-select-first v-model="selected.branch.city">
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-text-field :rules="[allRules.required, allRules.length(10)]" type="number" dense outlined
                        label="رقم الهاتف" v-model="selected.branch.phoneNumber"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" class="mx-auto mt-0">
                      <v-btn color="success" @click="addBranch()" :disabled="!validBranch">

                        {{ mode === 0 ? "اضافه" : "تعديل" }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="my-0 ammar text-center" dense v-if="allBranches">
                    <v-col cols="12" v-if="allBranches.length > 0">
                      <v-data-table :headers="branchesHeaders" :items="allBranches"
                        :server-items-length="allBranchestotal" :options.sync="optionsbranches" fixed-header
                        class="elevation-1" :footer-props="{
                          showFirstLastPage: true,
                          firstIcon: 'mdi-arrow-collapse-left',
                          lastIcon: 'mdi-arrow-collapse-right',
                          prevIcon: 'mdi-plus',
                          nextIcon: 'mdi-minus',
                          'items-per-page-text':
                            'عدد الفروع  ' + allBranchestotal + '',
                        }">
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn color="red" class="ml-2" icon elevation="2" outlined @click="deleteBranch(item.id)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn color="primary" elevation="2" icon @click="editBranch(item.id)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-card min-width="98%" class="mx-auto mr-5">
      <v-data-table v-can="'Clients.View'" :headers="headers" :items="entities" fixed-header :search="options.searchTerm"
        :items-per-page="15" :options.sync="options" :server-items-length="total" :loading="loadingStates.table"
        class="elevation-1" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد العملاء ' + total + '',
        }">
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.name" label="اسم العميل" hide-details dense outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.phoneNumber" label="جوال المسؤل" hide-details dense outlined>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.taxNum" label="الرقم الضريبي" hide-details dense outlined>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.commercialRegNum" label="رقم السجل التجاري" hide-details dense outlined>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete dense outlined hide-details auto-select-first label="المدينة" :items="citiesadd"
                item-value="id" item-text="name" v-if="citiesadd" v-model="options.branchId"></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="12">
              <v-btn color="error" @click="resetOptions">تفريغ
                <i class="fa fa-trash fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.isActivated`]="{ item }">
          <v-btn v-if="item.isActivated == false" v-can="'Clients.Delete'" color="green" outlined elevation="2"
            class="mx-2" @click="activateClient(item)" icon>
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
          <span v-if="item.isActivated == true">
            <v-chip label color="green">
              <i class="fa fa-check" aria-hidden="true"></i>
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">

          <v-btn v-can="'Clients.View'" color="orange" outlined elevation="2" class="mx-2"
            @click="openPolisa(item.allPolis, item)" icon>
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn v-can="'Clients.Delete'" color="red" class="ml-2" icon elevation="2" outlined
            @click="deleteClient(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn v-can="'Clients.Update'" color="primary" elevation="2" icon @click="openUserDialog(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="polisaDialog" width="99%" persistent>
      <v-card>
        <v-card-title class="headline">
          <!-- header  -->
          <v-row black class="d-block" style="width: 100% !important">
            <v-col cols="12">
              <v-tabs v-model="activeTab1" fixed-tabs next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline" show-arrows center-active background-color="orange accent-1"
                color="black" class="rounded-lg" light>
                <v-tab @click="showClient()"> جميع بوالص العميل </v-tab>
                <v-tab @click="showProduct()"> الاصناف </v-tab>
                <v-tab @click="showBranch()"> الفروع </v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <!-- polisas  -->
          <v-row v-if="showClientTab" class="text-center">
            <v-col cols="12">
              <ShipmentsTable v-model="userPolisa" />
            </v-col>
          </v-row>
          <!-- product  -->
          <v-row v-if="showProductTab" class="text-center">
            <v-col cols="12">
              <v-data-table :headers="productsHeaders" :items="allProducts" :server-items-length="allProductstotal"
                :options.sync="optionsproducts" fixed-header class="elevation-1" :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-plus',
                  nextIcon: 'mdi-minus',
                  'items-per-page-text':
                    'عدد اصناف العميل ' + allProductstotal + '',
                }">
                <template v-slot:[`item.includeTax`]="{ item }">
                  <span v-if="item.includeTax">
                    <v-chip label color="info">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </v-chip>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!-- branch  -->
          <v-row v-if="showBranchTab" class="text-center">
            <v-col cols="12">
              <v-data-table :headers="branchesHeaders" :items="allBranches" :server-items-length="allBranchestotal"
                :options.sync="optionsbranches" fixed-header class="elevation-1" :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-plus',
                  nextIcon: 'mdi-minus',
                  'items-per-page-text':
                    'عدد فروع العميل ' + allBranchestotal + '',
                }">
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="cancelview()"> إلغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Top50 Dialog -->
    <v-dialog v-model="top50Dialog" width="1000">
      <v-card>
        <v-card-title class="indigo white--text text-h5">
          اعلى 50 عميل
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
          <v-col cols="7">
            <v-treeview :items="top50data" :active.sync="active" :open.sync="open" activatable color="warning"
              open-on-click transition>
              <template v-slot:label="{ item }">
                <div v-if="item" class="treeeee">

                </div>
              </template>
              <template v-slot:prepend="{ item }">
                <v-icon v-if="!item.children">
                  mdi-account
                </v-icon>
                {{ item.name + (item.itemsCount ? ` (عدد الطرود: ${item.itemsCount})` : "") }}
              </template>
            </v-treeview>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col class="d-flex text-center">
            <v-scroll-y-transition mode="out-in">
              <!--   <v-card v-if="selectedtop50" :key="selectedtop50.id" class="pt-6 mx-auto" flat max-width="400">
                <v-treeview :items="salesManHistory">
                  <template v-slot:label="{ item }">
                    <div v-if="item" class="treeeee">
                    </div>
                  </template>
                  <template v-slot:prepend="{ item }">

                    {{ item.monthName + (item.itemsCount ? ` (عدد الطرود: ${item.itemsCount})` : "") }}
                  </template>
                </v-treeview>
              </v-card> -->
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ServiceTypesService from "/src/features/Settings/services/ServiceTypesService";
import ShipmentService from "../services/ShipmentService.js";
import MerchandisesApi from "../services/MerchandisesService.js";
import CitiesApi from "../../Settings/services/CityService.js";
import lineApi from "../services/LinesService.js";
import ApiService from "../services/ClientsService.js";
import PricesApi from "../services/PricesService";
import branchesApi from "../services/BranchesService";
import polisaTable from "./polisaTable";
export default {
  components: {},
  data() {
    return {
      citiesadd: [
        { id: 14, name: "جدة" },
        { id: 15, name: "القنفذة - الليث - المظليف" },
        { id: 20, name: "جيزان" },
        { id: 18, name: "ابوعريش" },
        { id: 16, name: "صبيا" },
        { id: 13, name: "القوز - حلى" },
        { id: 17, name: "بيش - الدرب - الشقيق" },
        { id: 19, name: "صامطه" },
        { id: 24, name: "سكاكا" },
        { id: 26, name: "عرعر" },
        { id: 25, name: "دومة الجندل" },
        { id: 27, name: "بيشة" },
        { id: 28, name: "الباحة" },
        { id: 29, name: "سبت العلايا" },
        { id: 31, name: "الرياض" },
        { id: 32, name: "الطائف" },
        { id: 33, name: "حائل" },
        { id: 34, name: "القريات" },
        { id: 35, name: "طبرجل" },
        { id: 36, name: "طريف" },
      ],
      activeTab: 0,
      activeTab1: 0,
      valid: false,
      validBranch: false,
      validProduct: false,
      startSearching: false,
      allBranches: [],
      allProducts: [],
      allBranchestotal: [],
      allProductstotal: [],
      showBranchTab: false,
      showProductTab: false,
      showClientTab: true,
      selected: {
        id: null,
        products: [],
        branches: [],
        product: {
          includeTax: false,
          merchandise: [],
          line: [],
        },
        branch: {},
        city: {},
      },
      polisaDialog: false,
      top50Dialog: false,
      userPolisa: null,
      total: 15,
      options: {
        name: null,
        empName: null,
        phoneNumber: null,
        idNum: null,
        lineNumber: null,
      },
      optionsproducts: {},
      optionsbranches: {},
      entities: [],
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "الاسم",
          value: "name",
          align: "center",
          sortable: false,
          width: "145px",
        },
        {
          text: "اسم المسؤل",
          value: "empName",
          align: "center",
          sortable: false,
          width: "145px",
        },
        {
          text: "المسمي الوظيفي",
          value: "empTitle",
          align: "center",
          sortable: false,
        },
        {
          text: "  هاتف المسؤل ",
          value: "phoneNumber",
          align: "center",
          sortable: false,
        },
        {
          text: "  الهوية ",
          value: "idNum",
          align: "start",
          sortable: false,
        },
        {
          text: "  الرقم الضريبى ",
          value: "taxNum",
          align: "start",
          sortable: false,
        },
        {
          text: "  رقم السجل التجارى ",
          value: "commercialRegNum",
          align: "start",
          sortable: false,
        },
        {
          text: "الفرع",
          value: "branch.name",
          align: "start",
          sortable: false,
        },
        {
          text: "التعميد",
          value: "isActivated",
          align: "start",
          sortable: false,
        },
        {
          text: "تعديل /مسح ",
          value: "actions",
          align: "center",
          sortable: false,
          width: "245px",
        },
      ],
      serviceTypes: [],
      userDialog: false,
      productDialog: false,
      branchDialog: false,
      loading: false,
      MerchandisesList: [],
      lines: null,
      cities: [],
      clients: {},
      mode: 0,
      outSourceData: null,
      p: null,
      dialog: false,
      persistent: false,
      maindata: {
        products: [],
        branches: [],
        product: { includeTax: false, merchandise: [], line: [] },
        branch: {},
      },
      active: [],
      open: [],
      top50data: [],
      clinetHistory: [],
    };
  },
  created() {
    ServiceTypesService.typeHead("", true).then((resp) => {
      this.serviceTypes = resp.data;
    });
  },
  async mounted() {
    try {
      this.activeTab = 0;
      this.activeTab1 = 0;
      this.loadingStates.table = true;
      this.loading = true;
      const Merchandises = await MerchandisesApi.typeHead("", true);
      lineApi.typeHead("", true, false, false).then(resp => {
        this.lines = resp.data;
      });
      let cities = await CitiesApi.typeHead("", true);
      this.cities = cities.data;
      this.MerchandisesList = Merchandises.data;
      this.getData();
      this.loading = false;
      this.loadingStates.table = false;
    } catch (error) {
      this.loading = false;
      this.loadingStates.table = false;
      console.log(error);
    }
  },
  methods: {
    fetchTop50() {
      this.active = [];
      this.open = [];
      this.top50data = [];
      this.salesManHistory = [];
      return ShipmentService.clientTop50().then((resp) => {
        this.top50data = resp.data;
      });
    },
    resetOptions() {
      this.options = {
        name: null,
        empName: null,
        phoneNumber: null,
        idNum: null,
        lineNumber: null,
        branchId: null,
      };
    },
    async openUserDialog(item) {
      this.selected = {
        ...this.entities.filter((e) => e.id == item.id)[0],
        product: { includeTax: false, merchandise: [], line: [] },
        branch: {},
      };
      this.dialog = true;
      this.showClient();
      const branches = await branchesApi.get(this.selected);
      const products = await PricesApi.get(this.selected);
      this.allBranches = branches.data.items;
      this.allBranchestotal = branches.data.total;
      this.allProducts = products.data.items;
      this.allProductstotal = products.data.total;
      this.mode = 1;
    },
    showClient() {
      this.showClientTab = true;
      this.showBranchTab = false;
      this.showProductTab = false;
    },
    showBranch() {
      this.showBranchTab = true;
      this.showProductTab = false;
      this.showClientTab = false;
    },
    showProduct() {
      this.showProductTab = true;
      this.showBranchTab = false;
      this.showClientTab = false;
    },

    async openPolisa(items, item) {
      this.showClient();
      this.selected = {
        ...this.entities.filter((e) => e.id == item.id)[0],
        product: { includeTax: false, merchandise: [], line: [] },
        branch: {},
      };
      ShipmentService.byClientId(item.id).then((resp) => {
        this.userPolisa = resp.data;
      });
      const branches = await branchesApi.get(this.selected);
      const products = await PricesApi.get(this.selected);
      this.allBranches = branches.data.items;
      this.allProducts = products.data.items;
      this.allBranchestotal = branches.data.total;
      this.allProductstotal = products.data.total;
      this.polisaDialog = true;
    },
    polisasValue(data, type) {
      if (type === 1) {
        let tottal = data.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.tottalPrice;
        }, 0);
        return tottal;
      } else {
        let tottal = data.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.taxs;
        }, 0);
        return tottal;
      }
    },
    viewTop50() {
      this.fetchTop50();
      this.top50Dialog = true;
    },
    add() {
      this.dialog = true;
      this.clearModel();
      this.showClient();
    },
    clearModel() {
      this.optionsproducts = {};
      this.optionsbranches = {};
      this.allBranches = [];
      this.allProducts = [];
      this.allBranchestotsl = null;
      this.allProductstotal = null;
      this.selected = {
        products: [],
        branches: [],
        product: { includeTax: false, merchandise: [], line: [] },
        branch: {},
      };
    },
    activateClient(item) {
      ApiService.updateActiveState(item.id)
        .then((resp) => {
          this.updateArr(this.entities, resp.data);
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    addClient() {
      ApiService.edit(this.selected).then((resp) => {
        if (!this.selected.id) {
          this.addToArr(this.entities, resp.data);
          this.selected = this.maindata;
          this.selected.id = resp.data.id;

          this.$dialog.notify.success("تم إضافة العميل بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          var i = this.entities.indexOf(
            this.entities.filter((e) => e.id == this.selected.id)[0]
          );
          this.updateArr(this.entities, resp.data);
          this.selected = this.maindata;

          this.$dialog.notify.success("تم تعديل العميل بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
        }
      }).catch((err) => {
        this.dialog = false;
        this.$dialog.notify.error(err.request.response, {
          position: "top-right",
          timeout: 3000,
        });
      });;
      this.dialog = false;
      this.mode = 0;
    },

    deleteClient(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العميل",
          title: "  حذف العميل  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العميل بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العميل", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },

    addBranch() {
      this.selected.branch.cityId = this.selected.branch.city.id;
      if (this.selected.id) {
        // edit it
        if (this.selected.branch.id) {
          let data = {
            id: this.selected.branch.id,
            clientData: { ...this.selected.branch, clientId: this.selected.id },
          };
          branchesApi.edit(data).then((resp) => {
            let findItem = this.allBranches.findIndex((i) => {
              return i.id == resp.id;
            });
            this.allBranches[findItem] = resp.data;
            this.selected.branch = {};
            this.$dialog.notify.success("تم تعديل الفرع بنجاح", {
              position: "top-right",
              timeout: 3000,
            });
          });
        } else {
          // add branch
          this.selected.branch.clientId = this.selected.id;
          let data = {
            clientId: this.selected.id,
            clientData: this.selected.branch,
          };
          branchesApi.add(data).then((resp) => {
            resp.clientId = this.selected.id;
            this.allBranches.push(resp.data);
            this.selected.branch = {};

            this.$dialog.notify.success("تم إضافة فرع بنجاح", {
              position: "top-right",
              timeout: 3000,
            });
          });
        }
      }
      this.mode = 0;
    },
    deleteBranch(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف الفرع",
          title: "  حذف فرع  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                branchesApi.delete(id).then((resp) => {
                  const index = this.allBranches.indexOf(
                    this.allBranches.filter((e) => e.id == id)[0]
                  );
                  if (index > -1) {
                    this.allBranches.splice(index, 1);
                  }
                  this.$dialog.notify.success("تم مسح الفرع بنجاح", {
                    position: "top-right",
                    timeout: 3000,
                  });
                });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    deleteProduct(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف الصنف",
          title: "  حذف مدينة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                PricesApi.delete(id).then((resp) => {
                  const index = this.allProducts.indexOf(
                    this.allProducts.filter((e) => e.id == id)[0]
                  );
                  if (index > -1) {
                    this.allProducts.splice(index, 1);
                  }
                  this.$dialog.notify.success("تم مسح الصنف بنجاح", {
                    position: "top-right",
                    timeout: 3000,
                  });
                });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    addMerchandise() {
      if (this.selected.id) {
        this.selected.product.clientId = this.selected.id;
        // edit it
        if (this.selected.product.id) {
          let data = {
            id: this.selected.product.id,
            clientData: {
              ...this.selected.product,
              clientId: this.selected.id,
            },
          };
          PricesApi.edit(data)
            .then((resp) => {
              this.updateArr(this.allProducts, resp.data);
              this.nullMerchandise();

              this.$dialog.notify.success("تم تعديل الصنف بنجاح", {
                position: "top-right",
                timeout: 3000,
              });
            })
            .catch((resp) => {
              var msg = "";
              this.$dialog.notify.error(msg, {
                position: "top-right",
                timeout: 3000,
              });
            });
        } else {
          // add product
          this.selected.product.clientId = this.selected.id;
          let data = {
            clientId: this.selected.id,
            clientData: this.selected.product,
          };
          PricesApi.add(data)
            .then((resp) => {
              this.allProducts = resp.data;
              this.nullMerchandise();
              this.$dialog.notify.success("تم إضافة صنف بنجاح", {
                position: "top-right",
                timeout: 3000,
              });
            })
            .catch((resp) => {
              var msg = `${resp.response.data}`;
              this.$dialog.notify.error(msg, {
                position: "top-right",
                timeout: 3000,
              });
            });
        }
      }
      this.mode = 0;
    },
    cancelEdit() {
      this.showClient();
      this.dialog = false;
      this.clearModel();
      this.activeTab = 0;
      this.mode = 0;
    },
    cancelview() {
      this.polisaDialog = false;
      this.activeTab1 = 0;
    },
    editProduct(item) {
      this.showProduct();
      this.mode = 1;
      if (item.id) {
        this.selected.product = this.allProducts.filter(
          (i) => i.id == item.id
        )[0];
      }
    },
    editBranch(item) {
      this.showBranch();
      this.mode = 1;
      if (item) {
        this.selected.branch = this.allBranches.filter((i) => i.id == item)[0];
      }
    },
    getData() {
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          console.log(this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    nullMerchandise() {
      this.selected.product = {
        includeTax: false,
        merchandise: [],
        line: [],
        price: null,
        priceAfterTax: null,
      };
    },
  },
  computed: {
    branchesHeaders() {
      let header = [
        { text: "الاسم", value: "name", align: "center", sortable: true },
        {
          text: "اسم المدينه",
          value: "city.name",
          align: "center",
          sortable: true,
        },
        {
          text: "رقم الجوال",
          value: "phoneNumber",
          align: "center",
          sortable: true,
        },
      ];
      if (this.dialog) {
        header.push({
          text: "تعديل /مسح ",
          value: "actions",
          align: "center",
          sortable: true,
        });
      }
      return header;
    },
    productsHeaders() {
      let header = [
        {
          text: "الصنف",
          value: "merchandise.name",
          align: "center",
          sortable: true,
        },
        { text: "الخط", value: "line.name", align: "center", sortable: true },
        {
          text: "السعر",
          value: "merchandise.price",
          align: "center",
          sortable: true,
        },
        {
          text: "السعر الخاص",
          value: "price",
          align: "center",
          sortable: true,
        },
        {
          text: "السعر بعد الضريبة",
          value: "priceAfterTax",
          align: "center",
          sortable: true,
        },
        {
          text: "متضمن الضريبه",
          value: "includeTax",
          align: "start",
          sortable: true,
        },
      ];
      if (this.dialog) {
        header.push({
          text: "تعديل /مسح ",
          value: "actions",
          align: "center",
          sortable: true,
        });
      }
      return header;
    },
  },
  watch: {
    options() {
      this.getData();
    },
    optionsproducts() {
      if (this.selected.id != null) {
        PricesApi.get(this.selected, this.optionsproducts)
          .then((resp) => {
            this.allProducts = resp.data.items;
            this.allProductstotal = resp.data.total;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    optionsbranches() {
      if (this.selected.id != null) {
        branchesApi
          .get(this.selected, this.optionsbranches)
          .then((resp) => {
            this.allBranches = resp.data.items;
            this.allBranchestotal = resp.data.total;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    "selected.product.line"(newValue) {
      if (newValue != null && newValue.length > 0) {
        this.selected.product.lineId = newValue[0].id;
        this.$set(
          this.selected.product,
          "lineId",
          this.selected.product.lineId
        );
      }
    },
    "selected.product.merchandise"(newValue) {
      if (newValue != null && newValue.length > 0) {
        this.selected.product.merchandiseId = newValue[0].id;
        this.$set(
          this.selected.product,
          "merchandiseId",
          this.selected.product.merchandiseId
        );
      }
    },
    "selected.product.price"(newValue) {
      this.selected.mainPrice = +this.selected.product.price;
      var addedValueTaxPercentage = this.$store.getters.tax;
      if (this.selected.product.includeTax) {
        var price = +this.selected.product.price;
        if (newValue) {
          var total =
            price -
            (price / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;
          this.selected.product.priceAfterTax = this.round(total, 2);
        }
      } else if (this.selected.product.includeTax == false) {
        var addedValueTax = addedValueTaxPercentage * this.selected.mainPrice;
        this.selected.product.priceAfterTax = this.round(
          this.selected.mainPrice + addedValueTax,
          2
        );
      }
      this.$set(
        this.selected.product,
        "priceAfterTax",
        this.selected.product.priceAfterTax
      );
    },

    "selected.product.includeTax"(newValue) {
      this.selected.mainPrice = +this.selected.product.price;
      var addedValueTaxPercentage = this.$store.getters.tax;
      var price = +this.selected.product.price;
      if (newValue == true) {
        var total =
          price -
          (price / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;
        this.selected.product.priceAfterTax = this.round(total, 2);
      } else if (newValue == false) {
        var addedValueTax = addedValueTaxPercentage * this.selected.mainPrice;
        this.selected.product.priceAfterTax = this.round(
          this.selected.mainPrice + addedValueTax,
          2
        );
      }
      this.$set(
        this.selected.product,
        "priceAfterTax",
        this.selected.product.priceAfterTax
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>